import "@nuux/sentry"
import "@hotwired/turbo-rails"

import "./application/bootstrap"
import "./application/turbo_frame_missing"
import "./controllers"

import "../assets/stylesheets/application.sass"


// if (process.env.NODE_ENV == "development") {
//   new EventSource('http://localhost:8001/esbuild').addEventListener('change', () => location.reload())
// }
